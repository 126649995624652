import logo from "./logo.svg";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Login from "./Components/Login/Login";
import CustomForm from "./Components/Form/Form";
import Result from "./Components/Result/Result";
import ResultB from "./Components/Result/ResultB";
import { useEffect } from "react";
import "./App.css";
import ResultForm6 from "./Components/Result/ResultForm6";
import ResultForm14 from "./Components/Result/ResultForm14";
import Double from "./Components/Result/Double";
import ResultForm10 from "./Components/Result/ResultForm10";

function App() {
  const currentPath = window.location.pathname;
  useEffect(() => {
    const userName = localStorage.getItem("username");
    if (!userName && currentPath !== "/") {
      window.location.href = "/";
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "form",
      element: <CustomForm />,
    },
    {
      path: "result",
      element: <Result />,
    },
    {
      path: "result-b",
      element: <ResultB />,
    },
    {
      path: "result-form6",
      element: <ResultForm6 />,
    },
    {
      path: "result-form14",
      element: <ResultForm14 />,
    },
    {
      path: "result-double",
      element: <Double />,
    },
    {
      path: "result-form10",
      element: <ResultForm10 />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
