import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Result.css";

const ResultForm10 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { amount, set, number, pana } = location.state;

  const SP =
    "137.128.146.236.245.290.380.470.489.560.678.579.129.138.147.156.237.246.345.390.480.570.589.679.120.139.148.157.238.247.256.346.490.580.670.689.130.149.158.167.239.248.257.347.356.590.680.789.140.159.168.230.249.258.267.348.357.456.690.780.123.150.169.178.240.259.268.349.358.367.457.790.124.160.179.250.269.278.340.359.368.458.467.890.125.134.170.189.260.279.350.369.378.459.468.567.126.135.180.234.270.289.360.379.450.469.478.568.127.136.145.190.235.280.370.389.460.479.569.578.";

  const DP =
    "119.155.227.335.344.399.588.669.100.110.228.255.336.499.660.688.778.200.166.229.337.355.445.599.779.788.300.112.220.266.338.446.455.699.770.400.113.122.177.339.366.447.799.889.500.114.277.330.448.466.556.880.899.600.115.133.188.223.377.449.557.566.700.116.224.233.288.440.477.558.990.800.117.144.199.225.388.559.577.667.900.118.226.244.299.334.488.668.677.550";

  const formData = location.state;

  const generateCombinations = (numbers) => {
    const combinations = new Set();

    if (pana === "SP") {
      const spNumbers = SP.split(".").filter((num) => num);
      const randomNumbers = [];
      const usedIndices = new Set();
      let iterations = 0;

      while (
        randomNumbers.length < 36 &&
        randomNumbers.length < spNumbers.length &&
        iterations < 1000
      ) {
        iterations++;
        const randomIndex = Math.floor(Math.random() * spNumbers.length);
        if (!usedIndices.has(randomIndex)) {
          usedIndices.add(randomIndex);
          const selectedNumber = spNumbers[randomIndex];
          if (selectedNumber.startsWith(number)) {
            randomNumbers.push(selectedNumber);
          }
        }
      }
      console.log("Random SP Numbers:", randomNumbers);
      return randomNumbers;
    } else if (pana === "DP") {
      const dpNumbers = DP.split(".").filter((num) => num);
      if (dpNumbers.length === 0 || !number) {
        console.error("No valid DP numbers or number provided.");
        return [];
      }
      const randomNumbers = [];
      const usedIndices = new Set();
      let iterations = 0;

      while (
        randomNumbers.length < 36 &&
        randomNumbers.length < dpNumbers.length &&
        iterations < 1000
      ) {
        iterations++;
        const randomIndex = Math.floor(Math.random() * dpNumbers.length);
        if (!usedIndices.has(randomIndex)) {
          usedIndices.add(randomIndex);
          const selectedNumber = dpNumbers[randomIndex];
          if (selectedNumber.startsWith(number)) {
            randomNumbers.push(selectedNumber);
          }
        }
      }
      console.log("Random DP Numbers:", randomNumbers);
      return randomNumbers;
    }
    return Array.from(combinations);
  };

  const combinations = number ? generateCombinations(number) : [];
  const sortedCombinations = combinations.sort();
  const shuffledCombinations = sortedCombinations;
  console.log("combinations", sortedCombinations);

  const totalCombinations = shuffledCombinations.length;

  const minElementsPerArray = Math.floor(totalCombinations / set);
  console.log("minElementsPerArray", minElementsPerArray);

  let extraElements = totalCombinations % set;

  const arrays = [];
  let currentIndex = 0;

  for (let i = 0; i < set; i++) {
    let elementsToAdd = minElementsPerArray;
    const newArray = shuffledCombinations.slice(
      currentIndex,
      currentIndex + elementsToAdd
    );
    arrays.push(newArray);

    currentIndex += elementsToAdd;
  }

  if (extraElements > 0) {
    const remainingElements = shuffledCombinations.slice(currentIndex);
    arrays.push(remainingElements);
  }
  console.log("arrays", arrays);
  const shareOnWhatsApp = (numbers, count) => {
    const message = `${numbers.join(",")} = ${amount} \n\nTotal: ${count}`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const shareAllOnWhatsApp = () => {
    const messages = arrays.map((box, idx) => {
      const numbers = box.join(",");
      return `${numbers} = ${amount} \nTotal: ${box.length}`;
    });
    const combinedMessage = `${messages.join("\n\n")}`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      combinedMessage
    )}`;
    console.log("WhatsApp URL:", whatsappURL);
    window.open(whatsappURL, "_blank");
  };

  console.log("arrays", arrays);

  return (
    <div className="main-container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("username");
            navigate("/");
          }}
        >
          Logout
        </button>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("username");
            navigate("/form", { state: { formData } });
          }}
        >
          Back
        </button>
      </div>
      <div className="result-page-container">
        <h2>Results</h2>
        <div className="boxes-container">
          {arrays.map((array, arrayIndex) => {
            if (array.length > 0) {
              return (
                <div className="array-box" key={arrayIndex}>
                  <div className="box">
                    <div>
                      {array.map((combination, index) => (
                        <React.Fragment key={index}>
                          <span className="number">{combination}</span>
                          {index !== array.length - 1 && (
                            <span className="separator">,</span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <span className="separator">=</span>
                    <span>{amount}</span>
                    <p style={{ marginTop: "10px" }}>
                      Total: {array.length * amount}
                    </p>
                    <button
                      onClick={() => shareOnWhatsApp(array, array.length)}
                      className="share-button"
                    >
                      Share
                    </button>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <button onClick={shareAllOnWhatsApp} className="share-all-button">
          Share All
        </button>
      </div>
    </div>
  );
};

export default ResultForm10;
