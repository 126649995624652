import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Form.css";
import Form6 from "./Form6";
import Form14 from "./Form14";
import FormA from "./FormA";
import FormB from "./FormB";

const Double = () => {
  const location = useLocation();
  console.log("location", location);
  const [activeForm, setActiveForm] = useState("formA");

  const [name, setName] = useState("");
  const [pana, setPana] = useState("");
  const [set, setSet] = useState("");
  const [separator, setSeparator] = useState(",");
  const [amount, setAmount] = useState("");
  const [text, setText] = useState("");

  const [form6Values, setForm6Values] = useState(null);
  const [form14Values, setForm14Values] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state !== null) {
      setActiveForm(location.state.formData.form);

      if (location.state.formData.form === "form6") {
        setForm6Values(location.state.formData);
      } else if (location.state.formData.form === "form14") {
        setForm14Values(location.state.formData);
      } else if (location.state.formData.form === "Double") {
        setName(location.state.formData.name);
        setPana(location.state.formData.pana);
        setSet(location.state.formData.set);
        setSeparator(location.state.formData.separator);
        setAmount(location.state.formData.amount);
        setText(location.state.formData.text);
      }
    }
  }, [location]);

  const handleFormDoubleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name,
      pana,
      set: parseInt(set, 10),
      separator,
      amount: parseInt(amount, 10),
      text,
      form: "Double",
    };
    navigate("/result-double", { state: formData });
  };

  return (
    <div className="custom-form">
      <form onSubmit={handleFormDoubleSubmit}>
        <h3 style={{ margin: "0px 0px 10px 0px" }}>Double</h3>
        <div className="form-group-container">
          <div style={{ width: "30%" }} className="form-group">
            <label htmlFor="set">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div style={{ width: "30%" }} className="form-group">
            <label htmlFor="pana">Select Pana</label>
            <select
              id="pana"
              name="pana"
              value={pana}
              onChange={(e) => setPana(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="Pana">Pana</option>
            </select>
          </div>
          <div style={{ width: "30%" }} className="form-group">
            <label htmlFor="set">Set</label>
            <input
              type="number"
              id="set"
              name="set"
              value={set}
              onChange={(e) => setSet(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group-container">
          <div style={{ width: "48%" }} className="form-group">
            <label htmlFor="separator">Separator</label>
            <select
              id="separator"
              name="separator"
              value={separator}
              onChange={(e) => setSeparator(e.target.value)}
              required
            >
              <option value=",">Comma (,)</option>
              <option value=".">Dot (.)</option>
              <option value="+">Pluse (+)</option>
              <option value="-">Minus (-)</option>
              <option value="/">Back slash (/)</option>
              <option value="=">Equal (=)</option>
              <option value="@">commercial at (@)</option>
              <option value="#">Hash (#)</option>
              <option value="_">Hyphen (_)</option>
              <option value='"'>Double Quotation Marks (")</option>
              <option value=":">Colon (:)</option>
              <option value="!">Exclamation mark (!)</option>
              <option value="?">Question mark (?)</option>
              <option value="'">Single Quotation Marks (')</option>
              <option value="*">Asterisk (*)</option>
            </select>
          </div>
          <div style={{ width: "48%" }} className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="text">Text (Use , or . to separate)</label>
          <textarea
            className="text-area-text"
            id="text"
            name="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Double;
