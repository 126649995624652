import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Result.css";

const ResultForm6 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount, set, moter, pana } = location.state;

  const formData = location.state;

  const generateCombinations = (numbers) => {
    const combinations = new Set();

    const sortWithZeroLast = (arr) => {
      return arr.sort((a, b) => {
        if (a === "0") return 1;
        if (b === "0") return -1;
        return a.localeCompare(b);
      });
    };

    if (pana === "DP") {
      // Logic for adding two same number neighbors in increasing order
      for (let i = 0; i < numbers.length; i++) {
        for (let j = 0; j < numbers.length; j++) {
          if (i === j) continue;
          const combination = sortWithZeroLast([
            numbers[i],
            numbers[i],
            numbers[j],
          ]).join("");
          if (!combinations.has(combination)) combinations.add(combination);
        }
      }
    } else if (pana === "TP") {
      // Logic for adding three same number neighbors in increasing order
      for (let i = 0; i < numbers.length; i++) {
        const combination = sortWithZeroLast([
          numbers[i],
          numbers[i],
          numbers[i],
        ]).join("");
        if (!combinations.has(combination)) combinations.add(combination);
      }
    } else {
      // Original combinations logic
      for (let i = 0; i < numbers.length; i++) {
        for (let j = 0; j < numbers.length; j++) {
          if (j === i || i > j) continue;
          for (let k = 0; k < numbers.length; k++) {
            if (k === i || k === j || i > k || j > k) continue;
            const combination = sortWithZeroLast([
              numbers[i],
              numbers[j],
              numbers[k],
            ]).join("");
            const reverseCombination = combination.split("").reverse().join("");
            if (!combinations.has(reverseCombination))
              combinations.add(combination);
          }
        }
      }
    }
    return Array.from(combinations);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const combinations = generateCombinations(moter.split(""));
  const sortedCombinations = combinations.sort();
  const shuffledCombinations = sortedCombinations;
  console.log("combinations", sortedCombinations);

  // Calculate total number of combinations
  const totalCombinations = shuffledCombinations.length;

  // Calculate minimum elements per array
  const minElementsPerArray = Math.floor(totalCombinations / set);
  console.log("minElementsPerArray", minElementsPerArray);

  // Calculate extra elements
  let extraElements = totalCombinations % set;

  // Split shuffledCombinations into arrays
  const arrays = [];
  let currentIndex = 0;

  for (let i = 0; i < set; i++) {
    let elementsToAdd = minElementsPerArray;

    // if (extraElements > 0) {
    //   elementsToAdd++;
    //   extraElements--;
    // }

    const newArray = shuffledCombinations.slice(
      currentIndex,
      currentIndex + elementsToAdd
    );
    arrays.push(newArray);

    currentIndex += elementsToAdd;
  }

  // Push any remaining elements into separate arrays
  if (extraElements > 0) {
    const remainingElements = shuffledCombinations.slice(currentIndex);
    arrays.push(remainingElements);
  }
  console.log("arrays", arrays);
  const shareOnWhatsApp = (numbers, count) => {
    const message = `${numbers.join(",")} = ${amount} \n\nTotal: ${count}`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const shareAllOnWhatsApp = () => {
    const messages = arrays.map((box, idx) => {
      const numbers = box.join(",");
      return `${numbers} = ${amount} \nTotal: ${box.length}`;
    });
    const combinedMessage = `${messages.join("\n\n")}`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      combinedMessage
    )}`;
    window.open(whatsappURL, "_blank");
  };

  console.log("arrays", arrays);

  return (
    <div className="main-container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("username");
            navigate("/");
          }}
        >
          Logout
        </button>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("username");
            navigate("/form", { state: { formData } });
          }}
        >
          Back
        </button>
      </div>
      <div className="result-page-container">
        <h2>Results</h2>
        <div className="boxes-container">
          {arrays.map((array, arrayIndex) => {
            if (array.length > 0) {
              return (
                <div className="array-box" key={arrayIndex}>
                  <div className="box">
                    <div>
                      {array.map((combination, index) => (
                        <React.Fragment key={index}>
                          <span className="number">{combination}</span>
                          {index !== array.length - 1 && (
                            <span className="separator">,</span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <span className="separator">=</span>
                    <span>{amount}</span>
                    <p style={{ marginTop: "10px" }}>
                      Total: {array.length * amount}
                    </p>
                    <button
                      onClick={() => shareOnWhatsApp(array, array.length)}
                      className="share-button"
                    >
                      Share
                    </button>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <button onClick={shareAllOnWhatsApp} className="share-all-button">
          Share All
        </button>
      </div>
    </div>
  );
};

export default ResultForm6;

// import React from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import "./Result.css";

// const ResultForm6 = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { amount, set, moter, pana } = location.state;

//   const generateCombinations = (numbers) => {
//     const combinations = new Set();

//     const sortWithZeroLast = (arr) => {
//       return arr.sort((a, b) => {
//         if (a === "0") return 1;
//         if (b === "0") return -1;
//         return a.localeCompare(b);
//       });
//     };

//     if (pana === "DP") {
//       // Logic for adding two same number neighbors in increasing order
//       for (let i = 0; i < numbers.length; i++) {
//         for (let j = 0; j < numbers.length; j++) {
//           if (i === j) continue;
//           const combination = sortWithZeroLast([
//             numbers[i],
//             numbers[i],
//             numbers[j],
//           ]).join("");
//           if (!combinations.has(combination)) combinations.add(combination);
//         }
//       }
//     } else if (pana === "TP") {
//       // Logic for adding three same number neighbors in increasing order
//       for (let i = 0; i < numbers.length; i++) {
//         const combination = sortWithZeroLast([
//           numbers[i],
//           numbers[i],
//           numbers[i],
//         ]).join("");
//         if (!combinations.has(combination)) combinations.add(combination);
//       }
//     } else {
//       // Original combinations logic
//       for (let i = 0; i < numbers.length; i++) {
//         for (let j = 0; j < numbers.length; j++) {
//           if (j === i || i > j) continue;
//           for (let k = 0; k < numbers.length; k++) {
//             if (k === i || k === j || i > k || j > k) continue;
//             const combination = sortWithZeroLast([
//               numbers[i],
//               numbers[j],
//               numbers[k],
//             ]).join("");
//             const reverseCombination = combination.split("").reverse().join("");
//             if (!combinations.has(reverseCombination))
//               combinations.add(combination);
//           }
//         }
//       }
//     }
//     return Array.from(combinations);
//   };

//   const shuffleArray = (array) => {
//     for (let i = array.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [array[i], array[j]] = [array[j], array[i]];
//     }
//     return array;
//   };

//   const combinations = generateCombinations(moter.split(""));
//   const shuffledCombinations = shuffleArray([...combinations]);
//   console.log("combinations", combinations);

//   // Calculate total number of combinations
//   const totalCombinations = shuffledCombinations.length;

//   // Calculate minimum elements per array
//   const minElementsPerArray = Math.floor(totalCombinations / set);
//   console.log("minElementsPerArray", minElementsPerArray);

//   // Calculate extra elements
//   let extraElements = totalCombinations % set;

//   // Split shuffledCombinations into arrays
//   const arrays = [];
//   let currentIndex = 0;

//   for (let i = 0; i < set; i++) {
//     let elementsToAdd = minElementsPerArray;

//     // if (extraElements > 0) {
//     //   elementsToAdd++;
//     //   extraElements--;
//     // }

//     const newArray = shuffledCombinations.slice(
//       currentIndex,
//       currentIndex + elementsToAdd
//     );
//     arrays.push(newArray);

//     currentIndex += elementsToAdd;
//   }

//   // Push any remaining elements into separate arrays
//   if (extraElements > 0) {
//     const remainingElements = shuffledCombinations.slice(currentIndex);
//     arrays.push(remainingElements);
//   }
//   console.log("arrays", arrays);
//   const shareOnWhatsApp = (numbers, count) => {
//     const message = `${numbers.join(",")} = ${amount} Rs\n\nTotal: ${count}`;
//     const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
//       message
//     )}`;
//     window.open(whatsappURL, "_blank");
//   };

//   const shareAllOnWhatsApp = () => {
//     const messages = arrays.map((box, idx) => {
//       const numbers = box.join(",");
//       return `${numbers} = ${amount} Rs\nTotal: ${box.length}`;
//     });
//     const combinedMessage = `${messages.join("\n\n")}`;
//     const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
//       combinedMessage
//     )}`;
//     window.open(whatsappURL, "_blank");
//   };

//   console.log("arrays", arrays);

//   return (
//     <div className="main-container">
//       <button
//         className="logout-button"
//         onClick={() => {
//           localStorage.removeItem("username");
//           navigate("/");
//         }}
//       >
//         Logout
//       </button>
//       <div className="result-page-container">
//         <h2>Results</h2>
//         <div className="boxes-container">
//           {arrays.map((array, arrayIndex) => {
//             if (array.length > 0) {
//               return (
//                 <div className="array-box" key={arrayIndex}>
//                   <div className="box">
//                     <ul>
//                       {array.map((combination, index) => (
//                         <React.Fragment key={index}>
//                           <li className="number">{combination}</li>
//                           {index !== array.length - 1 && (
//                             <span className="separator">,</span>
//                           )}
//                         </React.Fragment>
//                       ))}
//                     </ul>
//                     <span className="separator">=</span>
//                     <span>{amount} Rs</span>
//                     <p style={{ marginTop: "10px" }}>Total: {array.length}</p>
//                     <button
//                       onClick={() => shareOnWhatsApp(array, array.length)}
//                       className="share-button"
//                     >
//                       Share
//                     </button>
//                   </div>
//                 </div>
//               );
//             }
//           })}
//         </div>
//         <button onClick={shareAllOnWhatsApp} className="share-all-button">
//           Share All
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ResultForm6;
