import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Form.css";
import Form6 from "./Form6";
import Form14 from "./Form14";
import FormA from "./FormA";
import FormB from "./FormB";
import Double from "./Double";
import Form10 from "./Form10";

const CustomForm = () => {
  const location = useLocation();
  console.log("location", location);
  const [activeForm, setActiveForm] = useState("formA");

  const [name, setName] = useState("");
  const [pana, setPana] = useState("");
  const [set, setSet] = useState("");
  const [separator, setSeparator] = useState(",");
  const [amount, setAmount] = useState("");
  const [text, setText] = useState("");

  const [form6Values, setForm6Values] = useState(null);
  const [form14Values, setForm14Values] = useState(null);

  const [form10Values, setForm10Values] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.state !== null) {
      setActiveForm(location.state.formData.form);

      if (location.state.formData.form === "form6") {
        setForm6Values(location.state.formData);
      } else if (location.state.formData.form === "form14") {
        setForm14Values(location.state.formData);
      } else if (location.state.formData.form === "form10") {
        setForm10Values(location.state.formData);
      } else {
        setName(location.state.formData.name);
        setPana(location.state.formData.pana);
        setSet(location.state.formData.set);
        setSeparator(location.state.formData.separator);
        setAmount(location.state.formData.amount);
        setText(location.state.formData.text);
      }
    }
  }, [location]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name,
      pana,
      set: parseInt(set, 10),
      separator,
      amount: parseInt(amount, 10),
      text,
      form: "formA",
    };
    navigate("/result", { state: formData });
  };

  const handleFormDoubleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name,
      pana,
      set: parseInt(set, 10),
      separator,
      amount: parseInt(amount, 10),
      text,
      form: "Double",
    };
    navigate("/result-double", { state: formData });
  };

  const handleFormBSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name,
      pana,
      set: parseInt(set, 10),
      separator,
      amount: parseInt(amount, 10),
      text,
      form: "formB",
    };
    navigate("/result-b", { state: formData });
  };

  const handleFormClick = (form) => {
    setActiveForm(form);
  };

  return (
    <div className="main-container">
      <button
        className="logout-button"
        onClick={() => {
          localStorage.removeItem("username");
          navigate("/");
        }}
      >
        Logout
      </button>
      <div className="custom-form-container">
        <div className="custom-forms">
          <FormA />
          <FormB />
        </div>

        <div
          className="custom-forms"
          style={{
            marginTop: "25px",
          }}
        >
          <Form6 form6Values={form6Values} />
          <Form14 form14Values={form14Values} />
        </div>

        <div
          className="custom-forms"
          style={{
            marginTop: "25px",
          }}
        >
          <Double />
          <Form10 form10Values={form10Values} />
        </div>
      </div>
    </div>
  );
};

export default CustomForm;
