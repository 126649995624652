import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Form10 = ({ form10Values }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [set, setSet] = useState("");
  const [number, setNumber] = useState("");
  const [pana, setPana] = useState("");

  useEffect(() => {
    if (form10Values) {
      setAmount(form10Values.amount);
      setSet(form10Values.set);
      setNumber(form10Values.number);
      if (form10Values.pana === "") {
        setPana("SP");
      } else {
        setPana(form10Values.pana);
      }
    }
  }, [form10Values]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      amount,
      set,
      number,
      pana,
      form: "form10",
    };
    navigate("/result-form10", { state: formData });
  };

  return (
    <div className="custom-form">
      <form onSubmit={handleFormSubmit}>
        <h3 style={{ margin: "0px 0px 10px 0px" }}>Form 10</h3>
        <div className="form-group-container">
          <div style={{ width: "48%" }} className="form-group">
            <label htmlFor="pana">Select</label>
            <select
              id="pana"
              name="pana"
              value={pana}
              onChange={(e) => setPana(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="SP">SP</option>
              <option value="DP">DP</option>
            </select>
          </div>

          <div style={{ width: "48%" }} className="form-group">
            <label htmlFor="number">Number</label>
            <input
              type="number"
              id="number"
              name="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }} className="form-group">
            <label htmlFor="set">Set</label>
            <input
              type="number"
              id="set"
              name="set"
              value={set}
              onChange={(e) => setSet(e.target.value)}
              required
            />
          </div>

          <div style={{ width: "100%" }} className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form10;
