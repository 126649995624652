import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Result.css";
import { useNavigate } from "react-router-dom";

const ResultPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { set, separator, amount, text, name, form } = location.state;
  const formData = location.state;

  console.log("form", form);

  // Function to shuffle an array using Fisher-Yates (Knuth) algorithm
  const shuffleArray = (array) => {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  // Parse text and keep numbers as strings to preserve leading zeros
  const initialNumbers = text
    .split(/[.,\/+\-*=/@#_"':,!?' ]/)
    .map(Number)
    .filter((num) => !isNaN(num) && num !== 0);

  // Calculate result amount, total amount, and remaining amount
  const resultAmount = Math.floor(initialNumbers.length / set);
  const totalAmount = resultAmount * set * amount;
  const remainingAmount = initialNumbers.length * amount - totalAmount;

  // State for boxes
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    const distributeNumbers = () => {
      // Shuffle the initial numbers array
      const shuffledNumbers = shuffleArray([...initialNumbers]);

      // Initialize the boxes array
      const boxArray = Array.from({ length: set + 1 }, () => ({
        numbers: [],
        count: 0,
      }));
      const usedNumbers = Array.from({ length: set + 1 }, () => new Set());

      // Distribute shuffled numbers into boxes
      let currentNumberIndex = 0;
      let attempts = 0; // To prevent infinite loops

      while (
        currentNumberIndex < shuffledNumbers.length &&
        attempts < shuffledNumbers.length * 2
      ) {
        for (let i = 0; i < set + 1; i++) {
          const number = shuffledNumbers[currentNumberIndex];

          // Check if the number can be placed in the current box
          if (boxArray[i].numbers.length !== resultAmount) {
            boxArray[i].numbers.push(number);
            boxArray[i].count += amount;

            // Add the number to used numbers for this box
            usedNumbers[i].add(number);

            currentNumberIndex++;
          }

          // Increment attempts to prevent infinite loops
          attempts++;

          // Break if all numbers are distributed
          if (currentNumberIndex >= shuffledNumbers.length) break;
        }
      }

      // Add the remaining amount to the last box if needed
      const remainingNumbers = shuffledNumbers.slice(currentNumberIndex);
      if (remainingNumbers.length > 0) {
        remainingNumbers.forEach((number) => {
          boxArray[set].numbers.push(number);
          boxArray[set].count += amount;
        });
      }

      // Redistribute remaining numbers to boxes
      for (let i = 0; i < set; i++) {
        while (boxArray[i].numbers.length < resultAmount) {
          const number = boxArray[set].numbers.pop();
          if (number !== undefined && !boxArray[i].numbers.includes(number)) {
            boxArray[i].numbers.push(number);
            boxArray[i].count += amount;
            boxArray[set].count -= amount;
          } else if (number !== undefined) {
            // If the number is already in the box, add it back to the remaining numbers box
            boxArray[set].numbers.unshift(number);
          } else {
            break;
          }
        }
      }
      // Update state with the distributed boxes
      setBoxes(boxArray);
    };

    distributeNumbers();
  }, [set, amount, remainingAmount]);

  const shareOnWhatsApp = (numbers, count) => {
    const message = `${name} \n\n ${numbers.join(
      separator
    )} = ${amount} \n\nTotal: ${count}`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  const shareAllOnWhatsApp = () => {
    const messages = boxes.map((box, idx) => {
      const numbers = box.numbers.join(separator);
      return `${name}\n\n${numbers} = ${amount} \nTotal: ${box.count}`;
    });
    const combinedMessage = `${messages.join("\n\n")}`;
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      combinedMessage
    )}`;
    window.open(whatsappURL, "_blank");
  };
  console.log("boxes", boxes);
  return (
    <div className="main-container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("username");
            navigate("/");
          }}
        >
          Logout
        </button>
        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem("username");
            navigate("/form", { state: { formData } });
          }}
        >
          Back
        </button>
      </div>
      <div className="result-page-container">
        <h2>Results</h2>
        <div className="boxes-container">
          {boxes
            .filter((box) => box.numbers.length > 0)
            .map((box, idx) => (
              <div key={idx} className="box">
                <div>
                  <h4 style={{ marginBottom: "5px" }}>{name}</h4>
                  {box.numbers.length > 0 &&
                    box.numbers.map((num, numIdx) => (
                      <React.Fragment key={numIdx}>
                        <span className="number">{num}</span>
                        {numIdx < box.numbers.length - 1 && (
                          <span className="separator">{separator}</span>
                        )}
                      </React.Fragment>
                    ))}
                  <span> = {amount}</span>
                </div>
                <p style={{ marginTop: "10px" }}>Total: {box.count}</p>
                <button
                  onClick={() => shareOnWhatsApp(box.numbers, box.count)}
                  className="share-button"
                >
                  Share
                </button>
              </div>
            ))}
        </div>
        <button onClick={shareAllOnWhatsApp} className="share-all-button">
          Share All
        </button>
      </div>
    </div>
  );
};

export default ResultPage;
