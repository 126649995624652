import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Form6 = ({ form6Values }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [set, setSet] = useState("");
  const [moter, setMoter] = useState("");
  const [pana, setPana] = useState("");

  useEffect(() => {
    if (form6Values) {
      setAmount(form6Values.amount);
      setSet(form6Values.set);
      setMoter(form6Values.moter);
      if (form6Values.pana === "") {
        setPana("SP");
      } else {
        setPana(form6Values.pana);
      }
    }
  }, [form6Values]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      amount,
      set,
      moter,
      pana,
      form: "form6",
    };
    navigate("/result-form6", { state: formData });
  };

  return (
    <div className="custom-form">
      <form onSubmit={handleFormSubmit}>
        <h3 style={{ margin: "0px 0px 10px 0px" }}>Form 6</h3>
        <div className="form-group-container">
          <div style={{ width: "48%" }} className="form-group">
            <label htmlFor="pana">Select</label>
            <select
              id="pana"
              name="pana"
              value={pana}
              onChange={(e) => setPana(e.target.value)}
              required
            >
              <option value="SP">SP</option>
              <option value="DP">DP</option>
              <option value="TP">TP</option>
            </select>
          </div>

          <div style={{ width: "48%" }} className="form-group">
            <label htmlFor="moter">Enter Moter</label>
            <input
              type="number"
              id="moter"
              name="moter"
              value={moter}
              onChange={(e) => setMoter(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group-container">
          <div style={{ width: "48%" }} className="form-group">
            <label htmlFor="set">Set</label>
            <input
              type="number"
              id="set"
              name="set"
              value={set}
              onChange={(e) => setSet(e.target.value)}
              required
            />
          </div>

          <div style={{ width: "48%" }} className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form6;
